import { template as template_9efc5aaf85d5487487c0e2358a38e646 } from "@ember/template-compiler";
const FKControlMenuContainer = template_9efc5aaf85d5487487c0e2358a38e646(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
