import { template as template_38b7cd130aa04dcf8e033f2354f657de } from "@ember/template-compiler";
const SidebarSectionMessage = template_38b7cd130aa04dcf8e033f2354f657de(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
