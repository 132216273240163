import { template as template_afae548d8a074ee596e5b3c5ec91634b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_afae548d8a074ee596e5b3c5ec91634b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
