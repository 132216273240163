import { template as template_42656e1e1bdf47eda310d2e580d6f964 } from "@ember/template-compiler";
import Component from "@glimmer/component";
export default class FKControlCustom extends Component {
    static controlType = "custom";
    static{
        template_42656e1e1bdf47eda310d2e580d6f964(`
    <div class="form-kit__control-custom">
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
