import { template as template_864f24a0ea2b40dab99490e4b7e36c0a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_864f24a0ea2b40dab99490e4b7e36c0a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
